<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
import $ from "jquery";

export default {
    page: {
        title: "Detail Pendaftaran sertifikasi",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
        Layout,
        PageHeader,
    },
    data() {
        return {
            title: "Detail Pendaftaran sertifikasi",
            items: [
                {
                    text: "Pendaftaran",
                    href: "/",
                },
                {
                    text: "Detal Pendaftaran Permohonan",
                    active: true,
                },
            ],
            // Catch Error Axios
            axiosCatchError: null,

            //modal
            showModalSimpan: false,

            id: this.$route.params.id,
            pendaftaran_timeline: [],
            pendaftaran_skema: [],
            pendaftaran_asesi: [],
            pendaftaran_asesi_detail: [],
            pendaftaran_asesi_detail_bukti: [],
            data_pendaftaran_asesi: [],
            next_status: [],
            status_sekarang: [],
            pendaftaran: [],
            pendaftaran_surat_balasan: [],
            showModalPengisianForm: false,
            linkPreviewDokumen: null,
            nama_dokumen: null,
            disclaimerPengisianForm: false,
            dokumen_id: null,
            skema_id: null,
            count_harus_diisi: 0,
            count_bukti_persyaratan: 0,
            count_elemen_kompetensi: 0,

            // Proses pengisian form
            modalPengisianDokumen: false,
            form_dokumen_muk: null,
            pendaftaran_dokumen: null,

            // Proses pengisian dokumen relevan
            konfig_skema_unit: [],
            modalPengisianDokumenRelevan: false,
            persyaratan: [],
            ktp: null,
            foto: null,
            disclaimerPengisianFormRelevan: false,
            item_count: 0,
            dokumen_relevan: [],
            count_index_dokumen_relevan: 0,

            // Proses pengisian dokumen relevan
            bukti_persyaratan: [],
            modalPengisianDokumenPersyaratanDasar: false,
            disclaimerPengisianFormPersyaratan: false,
            persyaratan_dasar: [],

            //modal preview dokumen
            asesi_id: null,
            id_pendaftaran_asesi: null,
            linkPreviewForm: null,
            showModalPreviewForm: false,
            loadingformPreviewDoc: false,

            //modal password
            showModalPassword: false,
            tipe_dokumen: null,
            username: localStorage.session_username,
            password: null,
        };
    },
    mounted() {
        let loadInputScript = document.createElement("script");
        loadInputScript.setAttribute("src", process.env.VUE_APP_BACKEND_URL + "/js/input.js");
        document.head.appendChild(loadInputScript);
        this.getDetailPendaftaran();
    },
    methods: {
        fullDateTimeFormat(datetime) {
            return moment(datetime).format("YYYY-MM-DD");
        },
        getDetailPendaftaran() {
            let self = this;
            self.loadingTable = true;
            // get data detail penyusunan berdasarkan id
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });
            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "pendaftaran/detail",
                params: {
                    pendaftaran_id: self.id,
                    role_id: localStorage.session_role_active_id
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data[0];
                    if (response_data.meta.code == 200) {
                        self.count_harus_diisi = response_data_fix.count;
                        self.count_bukti_persyaratan = response_data_fix.count_bukti_persyaratan;
                        self.count_elemen_kompetensi = response_data_fix.count_elemen_kompetensi;
                        self.pendaftaran = response_data_fix.pendaftaran;
                        self.pendaftaran_skema = response_data_fix.pendaftaran_skema;
                        self.data_pendaftaran_asesi = response_data_fix.data_asesi_pendaftaran;
                        self.pendaftaran_asesi = response_data_fix.pendaftaran_asesi;
                        self.pendaftaran_asesi_detail = response_data_fix.pendaftaran_asesi[0];
                        // var apl_2 = JSON.parse(response_data_fix.pendaftaran_asesi[0].pengisian_dokumen_apl2);
                        // self.pendaftaran_asesi_detail_bukti = apl_2.bukti;
                        // self.persyaratan = response_data_fix.pendaftaran_asesi[0].dokumen_relevan;
                        self.foto = response_data_fix.pendaftaran_asesi[0].foto;
                        self.ktp = response_data_fix.pendaftaran_asesi[0].ktp;
                        self.pendaftaran_surat_balasan = response_data_fix.pendaftaran_surat_balasan;
                        self.pendaftaran_timeline = response_data_fix.pendaftaran_timeline;
                        self.next_status = response_data_fix.next_status;
                        self.status_sekarang = response_data_fix.status;
                        self.surat_permohonan = response_data_fix.surat_permohonan;
                        self.skema_id = self.pendaftaran_asesi_detail?.skema_id;
                        var currentDate = new Date();
                        self.nomor_surat_balasan = response_data_fix.urutan + "/SB/LSP/" + self.convertToRoman(currentDate.getMonth() + 1) + "/" + currentDate.getFullYear();
                        if (response_data_fix.dokumen_relevan_asesi) {
                            var json_response = response_data_fix.dokumen_relevan_asesi;
                            let clear_data_json_response = [];
                            $.each(json_response, function (indexInArray, valueOfElement) {
                                clear_data_json_response.push({
                                    id_bukti_dokumen: valueOfElement.id_bukti_dokumen,
                                    row_id: null,
                                    nama_bukti_dokumen: valueOfElement.nama_bukti_dokumen,
                                    file: valueOfElement?.path_dokumen,
                                });
                            });
                            self.dokumen_relevan = clear_data_json_response;
                        }
                        // self.dokumen_relevan = response_data_fix.dokumen_relevan;
                        self.count_index_dokumen_relevan = response_data_fix.count_index_dokumen_relevan;
                        console.log(response_data_fix.dokumen_relevan);
                        self.loadingTable = false;
                        Swal.close();
                    } else {
                        Swal.close();
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    Swal.close();
                });
        },
        pengisianDokumenRelevan(dokumen_id, skema_id) {
            let self = this;
            self.modalPengisianDokumenRelevan = true;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });
            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "pendaftaran/konfig-skema",
                params: {
                    dokumen_id: dokumen_id,
                    skema_id: skema_id,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data[0];
                    if (response_data.meta.code == 200) {
                        self.konfig_skema_unit = response_data_fix.skema.konfig_unit_kompetensi;
                        console.log(self.konfig_skema_unit);
                        Swal.close();
                    } else {
                        Swal.close();
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    Swal.close();
                });
        },
        pengisianDokumenPersyaratanDasar(dokumen_id, skema_id) {
            let self = this;
            self.modalPengisianDokumenPersyaratanDasar = true;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });
            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "pendaftaran/konfig-skema",
                params: {
                    dokumen_id: dokumen_id,
                    skema_id: skema_id,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data[0];
                    if (response_data.meta.code == 200) {
                        self.bukti_persyaratan = response_data_fix.bukti_persyaratan_dasar;
                        console.log(self.bukti_persyaratan);
                        Swal.close();
                    } else {
                        Swal.close();
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    Swal.close();
                });
        },
        getFormDokumen(dokumen_id,asesi_id) {
            let self = this;
            self.formPreviewDoc = null;
            self.showModalPreviewForm = true;
            self.loadingformPreviewDoc = true;
            axios
                .get(process.env.VUE_APP_BACKEND_URL_VERSION + "pendaftaran/preview-dokumen", {
                    params: {
                        pendaftaran_id: self.pendaftaran.id,
                        asesi_id: asesi_id,
                        dokumen_id: dokumen_id,
                    },
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + localStorage.access_token,
                    },
                })
                .then(function (response) {
                    var res_fix = response.data;
                    self.loadingformPreviewDoc = false;
                    self.linkPreviewForm = res_fix.data.pdf;
                    // is_ttd_asesi = false;
                    // if(res_fix.data.dokumen_asesmen.status_dokumen == 'sudah-input' && (dokumen_peninjau.includes(dokumen.dokumen_id) || metode_dokumen.includes(dokumen.dokumen_id))){
                    //     is_ttd_asesi = true;
                    // }
                    // self.is_ttd_asesi = is_ttd_asesi;
                    console.log(res_fix);
                    Swal.close();
                })
                .catch((e) => {
                    this.axiosCatchError = e.response;
                    Swal.close();
                });
        },
        convertToRoman(num) {
            var roman = {
                M: 1000,
                CM: 900,
                D: 500,
                CD: 400,
                C: 100,
                XC: 90,
                L: 50,
                XL: 40,
                X: 10,
                IX: 9,
                V: 5,
                IV: 4,
                I: 1,
            };
            var str = "";

            for (var i of Object.keys(roman)) {
                var q = Math.floor(num / roman[i]);
                num -= q * roman[i];
                str += i.repeat(q);
            }

            return str;
        },
        storeDokumenPendaftaran(nama_dokumen) {
            let self = this;
            self.password = null;
            self.showModalPengisianForm = false;

            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });

            var config_store_data = {
                method: "put",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "pendaftaran/store-dokumen",
                data: {
                    id: self.id,
                    pendaftaran_asesi_id: self.data_pendaftaran_asesi?.id,
                    nama_dokumen: nama_dokumen,
                    dokumen_id: self.dokumen_id,
                    skema_id: self.skema_id,
                    catatan_perbaikan: null
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config_store_data)
                .then(function (response) {
                    console.log(response);
                    self.getDetailPendaftaran();
                    self.disclaimerPengisianForm = false;
                })
                .catch(function (error) {
                    self.axiosCatchError = error.response.data.data.error;
                    Swal.close();
                });
        },
        pengisianDokumenMUK(dokumen_id) {
            let self = this;
            self.dokumen_id = dokumen_id;
            self.form_dokumen_muk = '<i class="fas fa-spinner fa-spin"></i> Loading...';
            self.modalPengisianDokumen = true;
            self.modalPengisianDokumenRelevan = false;
            axios
                .get(process.env.VUE_APP_BACKEND_URL_VERSION + "pendaftaran/get-form-dokumen-pendaftaran", {
                    params: {
                        pendaftaran_id: self.pendaftaran.id,
                        dokumen_id: dokumen_id,
                        aksi_dokumen: "pengisian_dokumen_apl",
                    },
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + localStorage.access_token,
                    },
                })
                .then(function (response) {
                    // var res_fix = response.data.data;
                    console.log(response.data.html);
                    self.form_dokumen_muk = response.data.html;
                })
                .catch((e) => {
                    this.axiosCatchError = e.response;
                    Swal.close();
                });
        },
        storePengisianFormPendaftaran() {
            let self = this;
            self.showModalPassword = false;
            self.modalPengisianDokumen = false;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });
            var form = $("#form-dokumen-pendaftaran");
            var mydata = new FormData(form[0]);
            mydata.append("pendaftaran_asesi_id", self.data_pendaftaran_asesi?.id);
            mydata.append("dokumen_id", self.dokumen_id);
            mydata.append("password", self.password);
            mydata.append("pengisian_oleh", "asesi");
            mydata.append("count_bukti_persyaratan", self.count_bukti_persyaratan);
            mydata.append("count_elemen_kompetensi", self.count_elemen_kompetensi);
            axios
                .post(process.env.VUE_APP_BACKEND_URL_VERSION + "pendaftaran/store-form-dokumen-pendaftaran", mydata, {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + localStorage.access_token,
                    },
                })
                .then(function (response) {
                    var res_data = response.data;
                    console.log(res_data);
                    if (res_data.status == false) {
                        Swal.fire({
                            icon: "warning",
                            title: "Peringatan!",
                            text: res_data.error,
                            showConfirmButton: false,
                        });
                        self.modalPengisianDokumen = true;
                    } else {
                        self.disclaimerPengisianForm = false;
                        self.getDetailPendaftaran();

                        if (self.dokumen_id == "b88c9b46-39dc-11ee-9a36-0242ac110009") {
                            self.pengisianDokumenPersyaratanDasar(self.dokumen_id, self.pendaftaran_asesi_detail.skema_id);
                        } else if (self.dokumen_id == "b88c9f10-39dc-11ee-9a36-0242ac110009") {
                            self.showModalPengisianForm = false;
                            Swal.fire({
                                icon: "success",
                                title: "Berhasil",
                                text: "Anda akan diarahkan ke halaman list pendaftaran segera",
                                timer: 2000,
                                timerProgressBar: true,
                                showCancelButton: false,
                                showConfirmButton: false,
                            }).then((result) => {
                                /* Read more about handling dismissals below */
                                if (result.dismiss === Swal.DismissReason.timer) {
                                    Swal.close();
                                    self.password = null;
                                    self.$router.push({ name: "all-pendaftaran_asesi" });
                                }
                            });
                            // self.pengisianDokumenRelevan('b88c9f10-39dc-11ee-9a36-0242ac110009', self.pendaftaran_asesi_detail.skema_id);
                        }
                    }
                })
                .catch((error) => {
                    Swal.fire({
                        icon: "error",
                        title: "Gagal",
                        text: error.response?.data?.data?.error,
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                    });
                    self.axiosCatchError = error.response;
                });
        },
        uploadDokumenRelevan(row) {
            let self = this;
            if ($("#upload-dok-relevan-" + row + "")[0].files[0]) {
                if ($("#upload-dok-relevan-" + row + "")[0].files[0].size < 5000000) {
                    $("#upload-loading-dok-relevan-" + row + "").html('<span class="badge bg-warning p-1"><i class="fa fa-refresh fa-spin"></i> Loading...</span>');
                    var urlres = process.env.VUE_APP_BACKEND_URL;
                    var FormData = require("form-data");
                    var data = new FormData();

                    data.append("file", $("#upload-dok-relevan-" + row + "")[0].files[0]);
                    data.append("pendaftaran_id", self.id);

                    var config = {
                        method: "post",
                        url: process.env.VUE_APP_BACKEND_URL_VERSION + "pendaftaran/upload-dok-relevan",
                        headers: {
                            Authorization: "Bearer " + localStorage.access_token,
                        },
                        data: data,
                    };
                    axios(config).then(function (response) {
                        var dir_name = response.data.dir_name;
                        urlres += dir_name;
                        $("#upload-loading-dok-relevan-" + row + "").html('<span class="badge bg-success p-1"><i class="fa fa-check"></i> Berhasil </span> <a href="' + urlres + '" target="_blank" style="padding-left:10px;"><span class="badge bg-success p-1"><i class="fa fa-eye"></i> Lihat File</span></a>');
                        // var elemen_replace = elemen_id.replace("-", "");
                        self.item_count += 1;
                        self.dokumen_relevan[row].row_id = row;
                        self.dokumen_relevan[row].file = dir_name;
                    });
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        html: "Ukuran file upload melebihi 5 Mb",
                    });
                }
            }
        },
        uploadDokumenPersyaratan(row, data_id) {
            let self = this;
            console.log($("#upload-dok-" + row + "")[0].files[0].size);
            if ($("#upload-dok-" + row + "")[0].files[0]) {
                if ($("#upload-dok-" + row + "")[0].files[0].size < 5000000) {
                    $("#upload-loading-dok-" + row + "").html('<span class="badge bg-warning p-1"><i class="fa fa-refresh fa-spin"></i> Loading...</span>');
                    var urlres = process.env.VUE_APP_BACKEND_URL;
                    var FormData = require("form-data");
                    var data = new FormData();

                    data.append("file", $("#upload-dok-" + row + "")[0].files[0]);
                    data.append("pendaftaran_id", self.id);

                    var config = {
                        method: "post",
                        url: process.env.VUE_APP_BACKEND_URL_VERSION + "pendaftaran/upload-dok-relevan",
                        headers: {
                            Authorization: "Bearer " + localStorage.access_token,
                        },
                        data: data,
                    };
                    axios(config).then(function (response) {
                        var dir_name = response.data.dir_name;
                        urlres += dir_name;
                        $("#upload-loading-dok-" + row + "").html('<span class="badge bg-success p-1"><i class="fa fa-check"></i> Berhasil </span> <a href="' + urlres + '" target="_blank" style="padding-left:10px;"><span class="badge bg-success p-1"><i class="fa fa-eye"></i> Lihat File</span></a>');
                        // var elemen_replace = elemen_id.replace("-", "");
                        self.persyaratan_dasar.push({
                            id: row,
                            data_id: data_id,
                            file: dir_name,
                        });
                    });
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        html: "Ukuran file upload melebihi 5 Mb",
                    });
                }
            }
        },
        uploadFoto() {
            let self = this;
            if ($("#upload-foto")[0].files[0]) {
                if ($("#upload-foto")[0].files[0].size < 5000000) {
                    $("#upload-loading-foto").html('<span class="badge bg-warning p-1"><i class="fa fa-refresh fa-spin"></i> Loading...</span>');
                    var urlres = process.env.VUE_APP_BACKEND_URL;
                    var FormData = require("form-data");
                    var data = new FormData();

                    data.append("file", $("#upload-foto")[0].files[0]);
                    data.append("pendaftaran_id", self.id);

                    var config = {
                        method: "post",
                        url: process.env.VUE_APP_BACKEND_URL_VERSION + "pendaftaran/upload-dok-relevan",
                        headers: {
                            Authorization: "Bearer " + localStorage.access_token,
                        },
                        data: data,
                    };
                    axios(config).then(function (response) {
                        var dir_name = response.data.dir_name;
                        urlres += dir_name;
                        $("#upload-loading-foto").html('<span class="badge bg-success p-1"><i class="fa fa-check"></i> Berhasil </span> <a href="' + urlres + '" target="_blank" style="padding-left:10px;"><span class="badge bg-success p-1"><i class="fa fa-eye"></i> Lihat File</span></a>');
                        self.foto = dir_name;
                    });
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        html: "Ukuran file upload melebihi 5 Mb",
                    });
                }
            }
        },
        uploadKTP() {
            let self = this;
            if ($("#upload-ktp")[0].files[0]) {
                if ($("#upload-ktp")[0].files[0].size < 5000000) {
                    $("#upload-loading-ktp").html('<span class="badge bg-warning p-1"><i class="fa fa-refresh fa-spin"></i> Loading...</span>');
                    var urlres = process.env.VUE_APP_BACKEND_URL;
                    var FormData = require("form-data");
                    var data = new FormData();

                    data.append("file", $("#upload-ktp")[0].files[0]);
                    data.append("pendaftaran_id", self.id);

                    var config = {
                        method: "post",
                        url: process.env.VUE_APP_BACKEND_URL_VERSION + "pendaftaran/upload-dok-relevan",
                        headers: {
                            Authorization: "Bearer " + localStorage.access_token,
                        },
                        data: data,
                    };
                    axios(config).then(function (response) {
                        var dir_name = response.data.dir_name;
                        urlres += dir_name;
                        $("#upload-loading-ktp").html('<span class="badge bg-success p-1"><i class="fa fa-check"></i> Berhasil </span> <a href="' + urlres + '" target="_blank" style="padding-left:10px;"><span class="badge bg-success p-1"><i class="fa fa-eye"></i> Lihat File</span></a>');
                        self.ktp = dir_name;
                    });
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        html: "Ukuran file upload melebihi 5 Mb",
                    });
                }
            }
        },
        storeDokumenRelevan() {
            let self = this;
            self.password = null;
            self.modalPengisianDokumenRelevan = false;
            self.disclaimerPengisianFormRelevan = false;

            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });

            // if(self.count_harus_diisi == self.persyaratan.length){
            var config_store_data = {
                method: "put",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "pendaftaran/store-dokumen-relevan",
                data: {
                    id: self.id,
                    pendaftaran_asesi_id: self.data_pendaftaran_asesi?.id,
                    persyaratan: JSON.stringify(self.dokumen_relevan),
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config_store_data)
                .then(function (response) {
                    if (response.data.status == false) {
                        Swal.fire({
                            icon: "warning",
                            title: "Peringatan!",
                            text: "Dokumen Relevan tidak lengkap!",
                            showConfirmButton: false,
                        });
                    } else {
                        self.getDetailPendaftaran();
                        self.disclaimerPengisianFormRelevan = false;
                        self.pengisianDokumenMUK("b88c9f10-39dc-11ee-9a36-0242ac110009");
                    }
                })
                .catch(function (error) {
                    self.axiosCatchError = error.response.data.data.error;
                    Swal.close();
                });
            // }else{
            //     Swal.fire({
            //         icon: 'warning',
            //         title: 'Peringatan!',
            //         text: "Dokumen Relevan tidak lengkap!",
            //         showConfirmButton: false,
            //     });
            //     self.modalPengisianDokumenRelevan = true;
            // }
        },

        storeDokumenPersyaratan() {
            let self = this;
            self.password = null;
            self.modalPengisianDokumenPersyaratanDasar = false;
            self.disclaimerPengisianFormPersyaratan = false;

            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });

            if (self.persyaratan_dasar.length == self.count_bukti_persyaratan) {
                var config_store_data = {
                    method: "put",
                    url: process.env.VUE_APP_BACKEND_URL_VERSION + "pendaftaran/store-dokumen-persyaratan",
                    data: {
                        id: self.id,
                        pendaftaran_asesi_id: self.data_pendaftaran_asesi?.id,
                        foto: self.foto,
                        persyaratan_dasar: JSON.stringify(self.persyaratan_dasar),
                        ktp: self.ktp,
                        count_bukti_persyaratan: self.count_bukti_persyaratan,
                        count_elemen_kompetensi: self.count_elemen_kompetensi,
                    },
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + localStorage.access_token,
                    },
                };

                axios(config_store_data)
                    .then(function (response) {
                        console.log(response);
                        self.getDetailPendaftaran();
                        self.disclaimerPengisianFormPersyaratan = false;
                    })
                    .catch(function (error) {
                        self.axiosCatchError = error.response.data.data.error;
                        Swal.close();
                    });
            } else {
                Swal.close();
                Swal.fire({
                    icon: "warning",
                    title: "Peringatan!",
                    text: "Dokumen Persyaratan tidak lengkap!",
                    showConfirmButton: false,
                });
                self.modalPengisianDokumenPersyaratanDasar = true;
            }
        },

        replaceString(str) {
            return str.replace(/[^A-Za-z0-9]/g, "");
        },

        addDokumenRelevan() {
            this.dokumen_relevan.push({
                nama_bukti_dokumen: null,
                id_bukti_dokumen: null,
                row_id: null,
                file: null,
            });
        },
        removeDokumenRelevan(index) {
            this.dokumen_relevan.splice(index, 1);
        },

        modalDokumenPendaftaran(nama_dokumen, path, id_pendaftaran_asesi, asesi_id) {
            let self = this;
            self.nama_dokumen = nama_dokumen;
            self.asesi_id = asesi_id;
            self.id_pendaftaran_asesi = id_pendaftaran_asesi;
            self.linkPreviewForm = process.env.VUE_APP_BACKEND_URL + "/" + path;
            self.showModalPreviewForm = true;
        },

        modalPassword() {
            let self = this;
            self.showModalPassword = false;
            self.modalPengisianDokumenPersyaratanDasar = false;
            self.modalPengisianDokumen = false;
            // config untuk re render component
            self.$nextTick(() => {
                self.showModalPassword = true;
            });
        },
        checkPassword() {
            let self = this;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });

            axios
                .post(process.env.VUE_APP_BACKEND_URL_VERSION + "auth/login", {
                    username: self.username,
                    password: self.password,
                    is_one_time: false,
                })
                .then((response) => {
                    var data_login = response.data;
                    // check status login
                    if (data_login.status_login == false) {
                        Swal.fire({
                            icon: "warning",
                            title: "Oopss",
                            text: data_login.message_login,
                            showCancelButton: false,
                            showConfirmButton: true,
                        });
                    } else {
                        self.showModalPassword = false;
                        // if(self.dokumen_id == 'b88c9b46-39dc-11ee-9a36-0242ac110009'){ // apl1
                        self.storePengisianFormPendaftaran();
                        // }else if(self.dokumen_id == 'b88c9f10-39dc-11ee-9a36-0242ac110009'){ // apl2
                        //     self.storePengisianFormPendaftaran();
                        // }
                    }
                })
                .catch((error) => {
                    var message = error.response.data.data.error;
                    if (message == "Session has been used") {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Akun anda telah login di perangkat lain, silahkan logout terlebih dahulu!",
                        });
                    } else if (message == "Account cant find") {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Periksa Kembali Username & Password Anda!",
                        });
                    } else if (message == "Something went wrong") {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Terjadi Kesalahan, Coba lagi nanti",
                        });
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Akun tidak ditemukan, periksa kembali username & password anda",
                        });
                    }
                });
        },
        resetDokumenPersyaratan() {
            let self = this;
            self.persyaratan_dasar = [];
            $.each(self.bukti_persyaratan, function (indexInArray, valueOfElement) {
                $("#upload-loading-dok-" + indexInArray + "").html("");
                $("#upload-dok-" + indexInArray + "").val("");
                console.log("#upload-loading-dok-" + indexInArray + "");
                console.log(valueOfElement);
            });
        },
        resetPengisianDokumen() {
            let self = this;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });
            var config_store_data = {
                method: "post",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "pendaftaran/reset-pengisian-dokumen",
                data: {
                    pendaftaran_asesi_id: self.data_pendaftaran_asesi.id,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config_store_data)
                .then(function (response) {
                    console.log(response);
                    Swal.fire({
                                icon: "success",
                                title: "Berhasil",
                                text: "Halaman akan diperbarui",
                                timer: 2000,
                                timerProgressBar: true,
                                showCancelButton: false,
                                showConfirmButton: false,
                            }).then((result) => {
                                /* Read more about handling dismissals below */
                                if (result.dismiss === Swal.DismissReason.timer) {
                                    Swal.close();
                                    self.getDetailPendaftaran();
                                }
                            });
                })
                .catch(function (error) {
                    self.axiosCatchError = error.response.data.data.error;
                    Swal.close();
                });
        },
    },
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="card-header">Data Sertifikasi</div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <table class="table table-responsive table-md table-bordered table-striped">
                                                    <tbody>
                                                        <tr>
                                                            <td>Kode Jadwal</td>
                                                            <td>{{ pendaftaran.kode_jadwal }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Tanggal Sertifikasi</td>
                                                            <td>{{ data_pendaftaran_asesi.tgl_usulan_asesmen }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Skema Sertifikasi</td>
                                                            <td>{{ pendaftaran_skema.skema_nama }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Nama Peserta</td>
                                                            <td>{{ data_pendaftaran_asesi.asesi_nama }}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div class="col-lg-12 mt-4">
                                                <table class="table mb-0 table-bordered table-condensed table-hover mx-2">
                                                    <thead class="bg-dark text-center text-white">
                                                        <tr>
                                                            <th>Status Sertifikasi</th>
                                                            <!-- <th>Dokumen Relevan</th> -->
                                                            <th>Daftar Form</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody class="text-center">
                                                        <tr>
                                                            <td style="vertical-align: center">
                                                                Data Belum Tersedia
                                                                <!-- <span class="badge bg-success p-1" v-if="pendaftaran_asesi_detail.status_dokumen_apl_1 == 2 && pendaftaran_asesi_detail.status_dokumen_apl_2 == 2">Kompeten</span> 
                                                                <span class="badge bg-success p-1" v-else-if="pendaftaran_asesi_detail.status_dokumen_apl_1 == 0 && pendaftaran_asesi_detail.status_dokumen_apl_2 == 0"></span> 
                                                                <span class="badge bg-danger p-1" v-else>Belum Kompeten</span>  -->
                                                            </td>
                                                            <!-- <td>
                                                                <button class="btn btn-info" v-on:click="pengisianDokumenRelevan('b88c9f10-39dc-11ee-9a36-0242ac110009', pendaftaran_asesi_detail.skema_id)"><i class="fas fa-upload"></i> Upload Dokumen Relevan</button>
                                                                <button class="btn btn-info" v-on:click="pengisianDokumenPersyaratanDasar('b88c9b46-39dc-11ee-9a36-0242ac110009', pendaftaran_asesi_detail.skema_id)"><i class="fas fa-upload"></i> Upload Dokumen Persyaratan Dasar</button>
                                                            </td> -->
                                                            <td>
                                                                <button class="btn btn-danger rounded-pill m-1 p-2" v-on:click="pengisianDokumenMUK('b88c9b46-39dc-11ee-9a36-0242ac110009')" v-if="data_pendaftaran_asesi.status_dokumen_apl_1 == 0">FR.APL 01</button>
                                                                <button class="btn btn-warning rounded-pill m-1 p-2" v-on:click="getFormDokumen('b88c9b46-39dc-11ee-9a36-0242ac110009',data_pendaftaran_asesi.asesi_id)"  v-else-if="data_pendaftaran_asesi.status_dokumen_apl_1 == 1">FR.APL 01</button>
                                                                <!-- <button class="btn btn-warning rounded-pill m-1 p-2" v-on:click="modalDokumenPendaftaran('FR.APL 01', data_pendaftaran_asesi.path_dokumen_apl_1, data_pendaftaran_asesi.id, data_pendaftaran_asesi.asesi_id)" v-else-if="data_pendaftaran_asesi.status_dokumen_apl_1 == 1">FR.APL 01</button> -->
                                                                <button class="btn btn-success rounded-pill m-1 p-2" v-on:click="pengisianDokumenMUK('b88c9b46-39dc-11ee-9a36-0242ac110009')" v-else-if="data_pendaftaran_asesi.status_dokumen_apl_1 == 2">FR.APL 01</button>
                                                                <button class="btn bg-dark text-white rounded-pill m-1 p-2" v-else>FR.APL 01</button>
                                                                
                                                                <button class="btn btn-danger rounded-pill m-1 p-2" v-on:click="pengisianDokumenRelevan('b88c9f10-39dc-11ee-9a36-0242ac110009', data_pendaftaran_asesi.skema_id)" v-if="data_pendaftaran_asesi.status_dokumen_apl_2 == 0">FR.APL 02</button>
                                                                <button class="btn btn-warning rounded-pill m-1 p-2" v-on:click="getFormDokumen('b88c9f10-39dc-11ee-9a36-0242ac110009',data_pendaftaran_asesi.asesi_id)" v-else-if="data_pendaftaran_asesi.status_dokumen_apl_2 == 1 && data_pendaftaran_asesi.is_apl_2_kompeten == true">FR.APL 02</button>
                                                                <!-- <button class="btn btn-warning rounded-pill m-1 p-2" v-on:click="modalDokumenPendaftaran('FR.APL 02', data_pendaftaran_asesi.path_dokumen_apl_2, data_pendaftaran_asesi.id, data_pendaftaran_asesi.asesi_id)" v-else-if="data_pendaftaran_asesi.status_dokumen_apl_2 == 1 && data_pendaftaran_asesi.is_apl_2_kompeten == true">FR.APL 02</button> -->
                                                                <button class="btn btn-info rounded-pill m-1 p-2" v-on:click="getFormDokumen('b88c9f10-39dc-11ee-9a36-0242ac110009',data_pendaftaran_asesi.asesi_id)" v-else-if="data_pendaftaran_asesi.status_dokumen_apl_2 == 1 && data_pendaftaran_asesi.is_apl_2_kompeten == false">FR.APL 02</button>
                                                                <button class="btn btn-success rounded-pill m-1 p-2" v-on:click="pengisianDokumenRelevan('b88c9f10-39dc-11ee-9a36-0242ac110009', data_pendaftaran_asesi.skema_id)" v-else-if="data_pendaftaran_asesi.status_dokumen_apl_2 == 2">FR.APL 02</button>
                                                                <button class="btn bg-dark text-white rounded-pill m-1 p-2" v-else>FR.APL 02</button>
                                                                <div v-if="data_pendaftaran_asesi.notes">
                                                                    <p>Alasan Penolakan Dokumen : {{ data_pendaftaran_asesi.notes }}</p>
                                                                </div>
                                                                <!-- <button class="btn btn-danger rounded-pill m-1 p-2" v-on:click="pengisianDokumenMUK('b88c9f10-39dc-11ee-9a36-0242ac110009')" v-if="pendaftaran_asesi_detail.status_dokumen_apl_2 == 0"> FR.APL 02</button>
                                                                <button class="btn btn-warning rounded-pill m-1 p-2" v-on:click="pengisianDokumenMUK('b88c9f10-39dc-11ee-9a36-0242ac110009')" v-else-if="pendaftaran_asesi_detail.status_dokumen_apl_2 == 1"> FR.APL 02</button>
                                                                <button class="btn btn-success rounded-pill m-1 p-2" , v-on:click="pengisianDokumenMUK('b88c9f10-39dc-11ee-9a36-0242ac110009')" v-else-if="pendaftaran_asesi_detail.status_dokumen_apl_2 == 2"> FR.APL 02</button>
                                                                <button class="btn bg-dark text-white rounded-pill m-1 p-2" v-else> FR.APL 02</button> -->
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <div class="row" v-if="pendaftaran.status_id == '24ed3180-3c10-11ee-a5ac-0242ac110009'">
                                                    <div class="col-md-8">
                                                    </div>
                                                    <div class="col-md-4 text-end">
                                                        <button class="btn btn-danger btn-sm" v-on:click="resetPengisianDokumen()"><i class="bx bx-loader"></i> Reset Pengisian Dokumen</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="mt-4">
                                                <h4>Keterangan</h4>
                                            </div>
                                            <div class="col-lg-4">
                                                <table class="table">
                                                    <tr>
                                                        <td style="width: 100px"><button class="btn bg-dark text-white rounded-pill" style="width: 75px">Hitam</button></td>
                                                        <td>Step sebelumnya belum disetujui admin LSP</td>
                                                    </tr>
                                                    <tr>
                                                        <td><button class="btn bg-danger btn-danger rounded-pill" style="width: 75px">Merah</button></td>
                                                        <td>Data belum dilengkapi oleh asesi atau tidak diterima</td>
                                                    </tr>
                                                    <tr>
                                                        <td><button class="btn bg-warning btn-warning rounded-pill" style="width: 75px">Kuning</button></td>
                                                        <td>Menunggu verifikasi admin LSP</td>
                                                    </tr>
                                                    <tr>
                                                        <td><button class="btn bg-success btn-success rounded-pill" style="width: 75px">Hijau</button></td>
                                                        <td>Data sudah disetujui admin LSP</td>
                                                    </tr>
                                                    <tr>
                                                        <td><button class="btn bg-info btn-info rounded-pill" style="width: 75px">Biru</button></td>
                                                        <td>FR APL 02 Belum Kompeten</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-header">
                        <h4 class="my-0">Timeline</h4>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12" style="overflow-x: scroll">
                                <ul class="timeline timeline-horizontal">
                                    <li class="timeline-item" v-for="(val, key) in pendaftaran_timeline" :key="key">
                                        <!--v-if-->
                                        <div class="timeline-badge success">
                                            <i class="fa fa-check" style="margin-top: 12px"></i>
                                        </div>
                                        <div class="timeline-panel">
                                            <div class="timeline-heading">
                                                <b class="timeline-title">{{ val.pejabat_nama }}</b>
                                                <p>
                                                    <small class="text-muted"><i class="fa fa-clock-o"></i> {{ fullDateTimeFormat(val.created_at) }}</small>
                                                </p>
                                            </div>
                                            <div class="badge bg-info">{{ val.status_nama }}</div>
                                            <br />
                                            <div class="timeline-body">
                                                <b>Catatan : </b><br />
                                                <p>{{ val.notes }}</p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
    <b-modal v-model="showModalPengisianForm" title="Preview Dokumen" title-class="text-black font-18" size="xl" hide-footer>
        <div class="row">
            <div class="card-body">
                <iframe v-bind:src="linkPreviewDokumen" width="100%" height="400"></iframe>
            </div>
            <div class="card-footer">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group"><input type="checkbox" v-model="disclaimerPengisianForm" /> Apakah ingin menyimpan form tersebut?</div>
                    </div>
                    <div class="col-md-6 text-end">
                        <b-button v-if="disclaimerPengisianForm == true" type="button" class="btn-sm" variant="primary" @click="storeDokumenPendaftaran(nama_dokumen)"><i class="fas fa-save"></i> Simpan Dokumen</b-button>
                        <b-button v-else type="button" class="btn-sm" variant="secondary"><i class="fas fa-save"></i> Simpan Dokumen</b-button>
                    </div>
                </div>
            </div>
        </div>
    </b-modal>
    <b-modal v-model="modalPengisianDokumen" title="Pengisian Dokumen" title-class="text-black font-18" size="lg" hide-footer>
        <div class="row">
            <div class="card-body">
                <form id="form-dokumen-pendaftaran">
                    <div class="form-group">
                        <div v-html="form_dokumen_muk"></div>
                    </div>
                </form>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group"><input type="checkbox" v-model="disclaimerPengisianForm" /> Apakah ingin menyimpan form tersebut?</div>
                    </div>
                    <div class="col-md-6 text-end">
                        <b-button v-if="disclaimerPengisianForm == true" type="button" class="btn-sm" variant="primary" @click="modalPassword(dokumen_id)"><i class="fas fa-save"></i> Simpan Dokumen</b-button>
                        <b-button v-else type="button" class="btn-sm" variant="secondary"><i class="fas fa-save"></i> Simpan Dokumen</b-button>
                    </div>
                </div>
            </div>
        </div>
    </b-modal>
    <b-modal v-model="modalPengisianDokumenRelevan" title="Upload Dokumen" title-class="text-black font-18" size="lg" hide-footer>
        <div class="row">
            <div class="card-body">
                <div class="row">
                    <h4>Dokumen Relevan</h4>
                    <!-- <div class="form-group">
                        <table class="table table-border table-bordered table-sm" v-for="(val_unit, key_unit) in konfig_skema_unit" :key="key_unit">
                            <thead>
                                <tr style="font-weight: bold;">
                                    <td style="width: 450px;">Unit Kompetensi {{ key_unit + 1 }}:</td>
                                    <td colspan="3">
                                        {{ val_unit.unit_kom_nama }}
                                    </td>
                                </tr>
                                <tr style="font-weight: bold;">
                                    <td>Dapatkah Saya ................?</td>
                                    <td>Bukti yang relevan</td>
                                </tr>
                            </thead>
                            <tbody v-for="(val_elemen, key_elemen) in val_unit.konfig_elemen" :key="key_elemen">
                                <tr>
                                    <td>
                                        {{ key_elemen + 1 }}. Elemen: {{ val_elemen.elemen_kom_nama }} <br />
                                        <div style="margin-left: 20px;" v-for="(val_kuk, key_kuk) in val_elemen.konfig_kuk" :key="key_kuk">
                                            {{ key_kuk + 1 }}. {{ val_kuk.kuk_nama }}
                                        </div>
                                    </td>
                                    <td>
                                        <table class="table-striped table-bordered p-2 w-100">
                                            <tbody>
                                                <tr v-for="(val_judul, key_judul) in pendaftaran_asesi_detail_bukti" :key="key_judul">
                                                    <div v-if="key_judul == replaceString(val_elemen.id)">
                                                        <div style="margin-left: 20px;" v-for="(val_judul_detail, key_judul_detail) in val_judul" :key="key_judul_detail">
                                                            <div class="row">
                                                                <div class="col-md-6">
                                                                    {{ val_judul_detail }}
                                                                </div>
                                                                <div class="col-md-6 text-end" v-if="val_judul_detail != null">
                                                                    <input type="file" required accept="application/pdf" @change="uploadDokumenRelevan(val_elemen.id, replaceString(val_judul_detail),key_judul_detail)" :id="'upload-' +replaceString(val_judul_detail)+'-'+val_elemen.id+'-'+key_judul_detail" class="form-control">
                                                                    <div class="respond-input-file float-left text-start" :id="'upload-loading-'+replaceString(val_judul_detail)+'-'+ val_elemen.id+'-'+key_judul_detail"></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div> -->
                    <div class="form-group">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th class="text-center">No</th>
                                    <th class="text-center" style="width: 50%">Dokumen Relevan</th>
                                    <th class="text-center">Upload</th>
                                    <th class="text-center">
                                        <button class="btn btn-success btn-sm" @click="addDokumenRelevan()"><i class="fas fa-plus"></i></button>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(val, key) in dokumen_relevan" :key="key">
                                    <td>{{ key + 1 }}</td>
                                    <td>
                                        <div class="form-group" v-if="key <= count_index_dokumen_relevan">
                                            {{ val.nama_bukti_dokumen }}
                                        </div>
                                        <div class="form-group" v-else>
                                            <input type="text" class="form-control" v-model="val.nama_bukti_dokumen" />
                                        </div>
                                    </td>
                                    <td>
                                        <input type="file" accept="application/pdf" @change="uploadDokumenRelevan(key)" :id="'upload-dok-relevan-' + key" class="form-control" />
                                        <div class="respond-input-file float-left" :id="'upload-loading-dok-relevan-' + key"></div>
                                        <input type="hidden" class="form-control" v-model="val.file" />
                                    </td>
                                    <td>
                                        <div class="form-group" v-if="key > count_index_dokumen_relevan">
                                            <button class="btn btn-danger btn-sm" @click="removeDokumenRelevan(key)"><i class="fas fa-times"></i></button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-8">
                        <div class="form-group"><input type="checkbox" v-model="disclaimerPengisianFormRelevan" /> Apakah ingin menyimpan dokumen tersebut?</div>
                    </div>
                    <div class="col-md-4 text-end">
                        <b-button v-if="disclaimerPengisianFormRelevan == true" type="button" class="btn-sm" variant="primary" @click="storeDokumenRelevan()"><i class="fas fa-save"></i> Simpan Dokumen Relevan</b-button>
                        <b-button v-else type="button" class="btn-sm" variant="secondary"><i class="fas fa-save"></i> Simpan Dokumen Relevan</b-button>
                    </div>
                </div>
            </div>
        </div>
    </b-modal>
    <b-modal v-model="modalPengisianDokumenPersyaratanDasar" title="Upload Dokumen" title-class="text-black font-18" size="lg" hide-footer>
        <div class="row">
            <div class="card-body">
                <!-- <div class="row" style="margin-bottom:10px">
                    <h4>Dokumen Pribadi</h4>
                    <div class="form-group">
                        <label for="">Fotokopi KTP/KK/SIM</label>
                        <input type="file" accept="application/pdf" @change="uploadKTP()" id="upload-ktp" class="form-control">
                        <div class="respond-input-file float-left" id="upload-loading-ktp"></div>
                    </div>
                    <div class="form-group">
                        <label for="">Pas Foto 3x4</label>
                        <input type="file" accept="image/png, image/gif, image/jpeg" @change="uploadFoto()" id="upload-foto" class="form-control">
                        <div class="respond-input-file float-left" id="upload-loading-foto"></div>
                    </div>
                </div> -->
                <div class="row">
                    <h4>Dokumen Persyaratan Dasar</h4>
                    <div class="form-group">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th class="text-center">No</th>
                                    <th class="text-center" style="width: 50%">Bukti Persyaratan Dasar</th>
                                    <th class="text-center">Aksi</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(val, key) in bukti_persyaratan" :key="key">
                                    <td>{{ key + 1 }}</td>
                                    <td>{{ val.nama_bukti_dokumen }} <span class="text-danger"> *</span></td>
                                    <td>
                                        <input type="file" accept="application/pdf" @change="uploadDokumenPersyaratan(key, val.id_bukti_dokumen)" :id="'upload-dok-' + key" class="form-control" />
                                        <div class="respond-input-file float-left" :id="'upload-loading-dok-' + key"></div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-8">
                        <div class="form-group"><input type="checkbox" v-model="disclaimerPengisianFormPersyaratan" /> Apakah ingin menyimpan dokumen tersebut?</div>
                    </div>
                    <div class="col-md-4 text-end">
                        <b-button v-if="disclaimerPengisianFormPersyaratan == true" type="button" class="btn-sm" variant="primary" @click="storeDokumenPersyaratan()"><i class="fas fa-save"></i> Simpan Dokumen Persyaratan Dasar</b-button>
                        <b-button v-else type="button" class="btn-sm" variant="secondary"><i class="fas fa-save"></i> Simpan Dokumen Persyaratan Dasar</b-button>
                        <b-button type="button" class="btn-sm" style="margin-left: 2px" variant="danger" @click="resetDokumenPersyaratan()"><i class="fas fa-undo-alt"></i> Reset</b-button>
                    </div>
                </div>
            </div>
        </div>
    </b-modal>
    <b-modal v-model="showModalPreviewForm" title="Preview Dokumen" title-class="text-black font-18" size="xl" hide-footer>
        <div class="row">
            <div class="card-body">
                <div class="row">
                    <div v-if="loadingformPreviewDoc"><i class="fas fa-spinner fa-spin"></i> Loading...</div>
                    <iframe v-else v-bind:src="linkPreviewForm" width="100%" height="400"></iframe>
                </div>
            </div>
        </div>
    </b-modal>
    <b-modal v-model="showModalPassword" title="Konfirmasi Password" title-class="text-black font-18" body-class="p-3" hide-footer>
        <div class="row">
            <form @submit.prevent="storePengisianFormPendaftaran()">
                <div class="form-group">
                    <input type="password" required v-model="password" id="" class="form-control" />
                </div>
                <div class="form-group text-end">
                    <button type="submit" class="btn btn-primary mt-3"><i class="fas fa-save"></i> Proses</button>
                </div>
            </form>
        </div>
    </b-modal>
</template>
<style scoped>
.required-field::before {
    content: "*";
    color: red;
}
</style>
